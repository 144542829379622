export default [
  {
    id: 1,
    text: 'home',
    url: '/',
  },
  {
    id: 2,
    text: 'leistungen',
    url: '/#leistungen',
  },
  {
    id: 3,
    text: 'referenzen',
    url: '/#referenzen',
  },
  {
    id: 4,
    text: 'kontakt',
    url: '/#kontakt',
  },
];
